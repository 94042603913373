<template>
  <section id="about" class="about section">
    <div class="container">
      <h2 class="section-title">About Enclave Executive Command</h2>
      <p class="section-subtitle">The Last True Guardians of America’s Legacy</p>
      <div class="about-content">
        <div class="about-text">
          <p>The Enclave is the last remnant of the pre-war United States government, led by the nation’s most elite political, military, and scientific minds!</p>
          
          <p>Our mission: to reclaim the wasteland and restore the United States to its rightful place. Armed with cutting-edge technology and unshakable resolve, we are not just survivors—we are the architects of America’s resurgence.</p>
          
          <p><a href="https://discord.gg/gQgtrRKefh" target="_blank" class="fw-bold">Join us</a> in this historic mission. Together, we will restore order, reassert America's dominance, and secure the Enclave’s vision for the future.</p>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'AboutSection'
}
</script>

<style scoped>

#about{
  background-color: #f6f6f6;
}
.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.section-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.section-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.about-text {
  flex: 1;
  text-align: center;
}

.about-text p {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    gap: 2rem;
  }

  .about-text, .about-image {
    flex: none;
    width: 100%;
  }

  .about-text p {
    font-size: 1rem;
  }

  .about-image img {
    max-width: 80%;
  }
}
</style>
