<template>
  <section id="community" class="community section">
    <div class="container">
      <h2 class="section-title">The Enclave Command</h2>
      <p class="section-subtitle">Step into the ranks of the Enclave, the true heirs of the United States, committed to reclaiming and restoring the nation's lost glory.</p>
      <div id="communityCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(image, index) in images" :key="index" :class="['carousel-item', index === 0 ? 'active' : '']">
            <img :src="image.src" :alt="image.alt" class="d-block w-100 carousel-image" @click="openLightbox(index)">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#communityCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#communityCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div class="thumbnails">
        <div v-for="(image, index) in limitedThumbnails" :key="index" class="thumbnail" @click="goToSlide(index)">
          <img :src="image.src" :alt="image.alt">
        </div>
      </div>
    </div>
    <div v-if="lightboxOpen" class="lightbox" @click="closeLightbox" @touchstart="touchStart" @touchend="touchEnd" @keydown="handleKeydown" tabindex="0">
      <button class="lightbox-close" @click.stop="closeLightbox">&times;</button>
      <img :src="images[currentImageIndex].src" :alt="images[currentImageIndex].alt" class="lightbox-image">
      <button class="lightbox-nav lightbox-prev" @click.stop="prevImage">&lt;</button>
      <button class="lightbox-nav lightbox-next" @click.stop="nextImage">&gt;</button>
    </div>
  </section>
</template>

<script>
import { Carousel } from 'bootstrap';

export default {
  name: 'CommunitySection',
  data() {
    return {
      images: [],
      lightboxOpen: false,
      currentImageIndex: 0,
      touchStartX: 0,
      touchEndX: 0,
      carousel: null,
    }
  },
  computed: {
    limitedThumbnails() {
      return this.images.slice(0, 99);
    }
  },
  created() {
    this.loadGalleryImages();
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Carousel(document.getElementById('communityCarousel'));
    });
  },
  methods: {
    loadGalleryImages() {
      const context = require.context('../assets/gallery', false, /\.(png|jpe?g|gif|webp)$/);
      this.images = context.keys().map((key) => ({
        src: require(`../assets/gallery/${key.slice(2)}`),
        alt: `Enclave Community Image ${key.slice(2, -4)}`
      }));
    },
    openLightbox(index) {
      this.currentImageIndex = index;
      this.lightboxOpen = true;
      this.$nextTick(() => {
        document.querySelector('.lightbox').focus();
      });
    },
    closeLightbox() {
      this.lightboxOpen = false;
    },
    prevImage() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
    },
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    touchStart(e) {
      this.touchStartX = e.changedTouches[0].screenX;
    },
    touchEnd(e) {
      this.touchEndX = e.changedTouches[0].screenX;
      this.handleSwipe();
    },
    handleSwipe() {
      if (this.touchStartX - this.touchEndX > 50) {
        this.nextImage();
      } else if (this.touchEndX - this.touchStartX > 50) {
        this.prevImage();
      }
    },
    handleKeydown(e) {
      if (e.key === 'ArrowLeft') {
        this.prevImage();
      } else if (e.key === 'ArrowRight') {
        this.nextImage();
      } else if (e.key === 'Escape') {
        this.closeLightbox();
      }
    },
    goToSlide(index) {
      this.carousel.to(index);
    },
  }
}
</script>

<style scoped>
#community {
  background: linear-gradient(145deg, #394039, #0D0D0D);
  padding: 80px 0;
}
.section-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f6f6f6;
}
.section-subtitle {
  font-size: 1.5rem;
  margin-bottom: 40px;
  color: #f6f6f6;
}
.carousel {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}
.carousel-item {
  height: 500px;
}
.carousel-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease;
}
.carousel-image:hover {
  transform: scale(1.05);
}
.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}
.thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.thumbnail {
  width: 80px;
  height: 60px;
  margin: 5px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
}
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.thumbnail:hover img {
  transform: scale(1.1);
}
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.lightbox-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
.lightbox-close,
.lightbox-nav {
  position: absolute;
  background: none;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  transition: color 0.3s ease;
}
.lightbox-close:hover,
.lightbox-nav:hover {
  color: #F2E641;
}
.lightbox-close {
  top: 20px;
  right: 20px;
}
.lightbox-prev {
  left: 20px;
}
.lightbox-next {
  right: 20px;
}
@media (max-width: 768px) {
  .carousel-item {
    height: 300px;
  }
  .section-title {
    font-size: 2rem;
  }
  .section-subtitle {
    font-size: 1.2rem;
  }
  .thumbnail {
    width: 60px;
    height: 45px;
  }
}
</style>
