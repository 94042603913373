<template>
    <div class="enclave-intro">
      <div class="intro-content">
        <div class="logo-container">
          <img src="../assets/images/logo.webp" alt="Enclave Logo" class="intro-logo">
        </div>
        <h1 class="intro-title">Enclave Executive Command</h1>
        <p class="intro-tagline">Initializing the future of our great nation</p>
        <div class="loading-bar">
          <div class="progress"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EnclaveIntro',
    mounted() {
      setTimeout(() => {
        this.$emit('intro-complete');
      }, 5000);
    }
  }
  </script>
  
  <style scoped>
  .enclave-intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(145deg, #394039, #0D0D0D);
    color: white;
  }
  
  .intro-content {
    text-align: center;
    animation: fadeIn 1s ease-out;    
  }
  
  .logo-container {
    margin-bottom: 30px;
  }
  
  .intro-logo {
    width: 120px;
    height: auto;
    animation: pulse 1.25s infinite;
  }
  
  .intro-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 50px;

  }
  
  .intro-tagline {
    font-size: 18px;
    margin-bottom: 30px;
    opacity: 0.8;
  }
  
  .loading-bar {
    width: 200px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0 auto;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .progress {
    width: 0;
    height: 100%;
    background-color: #394039;
    animation: loading 5s linear;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  @keyframes loading {
    from { width: 0; }
    to { width: 100%; }
  }
  </style>
  