<template>
    <section class="video-section">
      <div class="container">
        <h2 class="section-title">Enclave Recruitment Broadcast</h2>
        <p class="section-subtitle">Join the forces that will reclaim America. Restore order to the wasteland and ensure a future where the Enclave’s vision of supremacy and strength!</p>
        <div class="video-wrapper">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/1gGLJVLl-Lk" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'VideoSection'
  }
  </script>
  
  <style scoped>

    .section-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f6f6f6;
    }
    .section-subtitle {
    font-size: 1.5rem;
    margin-bottom: 40px;
    color: #f6f6f6;
    }
  .video-section {
    padding: 80px 0;
    text-align: center;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 0 auto;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  </style>
  